<template>
  <div>
    <!-- Filter -->
    <b-card
      footer-tag="footer"
      class="mb-1 card-filter-container"
    >
      <b-row>
        <b-col
          sm="12"
          md="6"
          lg="3"
          class="card-filter-item"
        >
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('date_from')"
              />
            </b-input-group-prepend>
            <b-form-datepicker
              v-model="filter.startDate"
              :max="filter.endDate"
            />
          </b-input-group>
          <b-input-group class="mb-1-sm">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('time_from')"
              />
            </b-input-group-prepend>
            <b-form-timepicker
              v-model="filter.startTime"
              show-seconds
              locale="en"
            />
          </b-input-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="3"
          class="card-filter-item"
        >
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('date_to')"
              />
            </b-input-group-prepend>
            <b-form-datepicker
              v-model="filter.endDate"
              :min="filter.startDate"
            />
          </b-input-group>
          <b-input-group class="mb-1-sm mb-1-md">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('time_to')"
              />
            </b-input-group-prepend>
            <b-form-timepicker
              v-model="filter.endTime"
              show-seconds
              locale="en"
            />
          </b-input-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="3"
          class="card-filter-item"
        >
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('username')"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filter.username"
              autocomplete="off"
            />
          </b-input-group>
          <b-input-group class="mb-1-sm mb-1-md-remove">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('round_id')"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filter.roundId"
              autocomplete="off"
            />
          </b-input-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="3"
          class="card-filter-item"
        >
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('transaction_type')"
              />
            </b-input-group-prepend>
            <b-form-select
              v-model="filter.transactionType"
              :options="transactionTypeOptions"
            />
          </b-input-group>
          <b-input-group class="mb-1-sm mb-1-md-remove">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('item_per_page')"
              />
            </b-input-group-prepend>
            <b-form-select
              v-model="filter.perPage"
              :options="itemPerpageOptions"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="12"
          lg="12"
          class="card-filter-item mt-1"
        >
          <b-button
            variant="primary float-right"
            @click="loadData"
            v-text="$t('search')"
          />
        </b-col>
      </b-row>
    </b-card>
    <!-- Table Container Card -->
    <b-overlay
      :show="loading"
      opacity="0.80"
      rounded="sm"
    >
      <div class="card">
        <div class="card-body">
          <div class="vgt-wrap">
            <div class="vgt-inner-wrap">
              <div class="vgt-responsive game-history-table">
                <table class="vgt-table bordered">
                  <thead>
                    <tr>
                      <th
                        class="vgt-left-align"
                      >
                        <span v-text="$t('username')" />
                      </th>
                      <th
                        class="vgt-left-align"
                      >
                        <span v-text="$t('type')" />
                      </th>
                      <th
                        class="vgt-left-align"
                      >
                        <span v-text="$t('casino')" />
                      </th>
                      <th
                        class="vgt-left-align"
                      >
                        <span v-text="$t('round_id')" />
                      </th>
                      <th
                        class="vgt-left-align"
                        style="width: 50px;"
                      >
                        <span v-text="$t('data')" />
                      </th>
                      <th
                        class="vgt-left-align"
                      >
                        <span v-text="$t('created_at')" />
                      </th>
                      <th
                        class="vgt-left-align"
                      >
                        <span v-text="$t('status')" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in items"
                      :key="item.id"
                    >
                      <!-- Username -->
                      <td class="vgt-left-align">
                        <span v-text="item.username" />
                      </td>
                      <!-- Type -->
                      <td class="vgt-left-align">
                        <span v-text="item.type" />
                      </td>
                      <!-- Casino -->
                      <td class="vgt-left-align">
                        <span v-text="item.casino" />
                      </td>
                      <!-- Round ID -->
                      <td class="vgt-left-align">
                        <span v-text="item.round_id" />
                      </td>
                      <!-- Data -->
                      <td class="vgt-left-align">
                        <pre>{ {{ JSON.stringify(item.data, null, 2).substr(1) }}</pre>
                      </td>
                      <!-- Created At -->
                      <td class="vgt-left-align">
                        <span v-text="item.created_at" />
                      </td>
                      <!-- Status -->
                      <td class="vgt-left-align">
                        <span
                          v-if="item.status === 200"
                          v-text="item.status"
                        />
                        <span
                          v-else
                          class="text-danger"
                          v-text="item.status"
                        />
                        <b-button
                          v-if="item.displayStatus === false"
                          variant="outline-primary"
                          size="sm"
                          class="ml-1"
                          @click="item.displayStatus = true"
                          v-text="$t('show')"
                        />
                        <b-button
                          v-if="item.displayStatus === true"
                          variant="outline-primary"
                          size="sm"
                          class="ml-1"
                          @click="item.displayStatus = false"
                          v-text="$t('hide')"
                        />
                        <pre
                          v-if="item.displayStatus === true"
                          class="mt-1"
                        >{ {{ JSON.stringify(item.responseStatusCode, null, 2).substr(1) }}</pre>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <b-pagination
            v-model="filter.page"
            :per-page="perPage"
            hide-goto-end-buttons
            :total-rows="totalPage"
            align="center"
            @change="loadData"
          />
        </div>
      </div>
    </b-overlay>

  </div>
</template>

<script>

import {
  BOverlay,
  BCard,
  BPagination,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BFormDatepicker,
  BFormTimepicker,
  BButton,
  BFormSelect,
} from 'bootstrap-vue'

import Seamless from '@/models/Seamless'

import 'vue-good-table/dist/vue-good-table.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { EventBus } from '../../../eventbus'

export default {
  name: 'ReportGameHistory',
  components: {
    BOverlay,
    BCard,
    BPagination,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BFormDatepicker,
    BFormTimepicker,
    BButton,
    BFormSelect,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      items: [],
      loading: true,
      perPage: 10,
      totalPage: 0,
      filter: {
        page: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1,
        perPage: typeof this.$route.query.perPage !== 'undefined' ? this.$route.query.perPage : 25,
        username: typeof this.$route.query.username !== 'undefined' ? this.$route.query.username : '',
        roundId: typeof this.$route.query.roundId !== 'undefined' ? this.$route.query.roundId : '',
        transactionType: typeof this.$route.query.transactionType !== 'undefined' ? this.$route.query.transactionType : '',
        startDate: typeof this.$route.query.startDate !== 'undefined' ? this.$route.query.startDate : `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() - 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() - 86400000).getDate())}`,
        endDate: typeof this.$route.query.endDate !== 'undefined' ? this.$route.query.endDate : `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() + 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() + 86400000).getDate())}`,
        startTime: typeof this.$route.query.startTime !== 'undefined' ? this.$route.query.startTime : '00:00:00',
        endTime: typeof this.$route.query.endTime !== 'undefined' ? this.$route.query.endTime : '23:59:59',
      },
      currentFilter: {
        page: 1,
        perPage: typeof this.$route.query.perPage !== 'undefined' ? this.$route.query.perPage : 50,
        username: typeof this.$route.query.username !== 'undefined' ? this.$route.query.username : '',
        roundId: typeof this.$route.query.roundId !== 'undefined' ? this.$route.query.roundId : '',
        transactionType: typeof this.$route.query.transactionType !== 'undefined' ? this.$route.query.transactionType : '',
        startDate: typeof this.$route.query.startDate !== 'undefined' ? this.$route.query.startDate : `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() - 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() - 86400000).getDate())}`,
        endDate: typeof this.$route.query.endDate !== 'undefined' ? this.$route.query.endDate : `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() + 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() + 86400000).getDate())}`,
        startTime: typeof this.$route.query.startTime !== 'undefined' ? this.$route.query.startTime : '00:00:00',
        endTime: typeof this.$route.query.endTime !== 'undefined' ? this.$route.query.endTime : '23:59:59',
      },
      transactionTypeOptions: [
        { value: '', text: 'All' },
        { value: 'bets', text: 'Bets' },
        { value: 'results', text: 'Results' },
        { value: 'balance', text: 'Balance' },
        { value: 'return', text: 'Return' },
      ],
      itemPerpageOptions: [
        { value: '25', text: '25' },
        { value: '50', text: '50' },
        { value: '100', text: '100' },
        { value: '200', text: '200' },
        { value: '300', text: '300' },
      ],
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData(page = 1) {
      this.loading = true
      const searchParams = {}
      this.filter.page = page
      Object.keys(this.filter).forEach(e => {
        if (typeof this.filter[e] === 'number') {
          if (this.filter[e] > 0) {
            searchParams[e] = this.filter[e]
          }
        } else if (typeof this.filter[e] === 'string') {
          if (this.filter[e] !== null && this.filter[e].length > 0) {
            searchParams[e] = this.filter[e]
          }
        }
      })

      this.$router.replace({ query: searchParams })
      const response = await Seamless.log(searchParams)
      if (response.data.status) {
        this.items = response.data.data
        this.totalPage = response.data.meta.pagination.total
        this.perPage = response.data.meta.pagination.per_page
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: this.$t(response.data.message),
          },
        }, {
          position: 'top-center',
        })
      }
      EventBus.$emit('getMyUpdatedUserData')
      this.loading = false
    },
  },
}
</script>
